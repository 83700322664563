////////// BACKGROUND COLOR //////////
.bg-primaryColor {
  background-color: rgba(255, 255, 255, 1);
}
.bg-secondaryColor {
  background-color: rgba(198, 40, 40, 1);
}
.bg-thirdColor {
  background-color: rgba(0, 0, 0, 1);
}
.bg-fourthColor {
  background-color: rgba(64, 63, 64, 1);
}
.bg-fifthColor {
  background-color: rgba(64, 63, 64, 0.6);
}
.bg-sixthColor {
  background-color: rgba(0, 0, 0, 1);
}
.bg-seventhColor {
  background-color: rgba(64, 63, 64, 1);
}
